import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import OneColSection from "../components/OneColSection"
import { graphql } from "gatsby"
import { now, timestampUnix } from "../components/dateFn"
import EventOverviewCard from "../components/EventOverviewCard"
import { CardDeck } from "react-bootstrap"

function KinderFamilienProgramm(props) {
  const allFamilyEvents = props.data.prismic.allPages.edges
  const allFutureFamilyEvents = []

  for (let index = 0; index < allFamilyEvents.length; index++) {
    const element = allFamilyEvents[index]
    //get only Events in the future
    const endTimeUnix = timestampUnix(element.node.body[0].primary.end_time)

    if (endTimeUnix > now) {
      allFutureFamilyEvents.push(element)
    }

    element.node.body[0].primary.slug = element.node._meta.uid
  }

  return (
    <Layout>
      <>
        <PageTitle
          pageTitle={
            props.data.prismic.allFamilienprogramms.edges[0].node.page_title
          }
        />
        <OneColSection
          content={props.data.prismic.allFamilienprogramms.edges[0].node.text}
        />
        {allFutureFamilyEvents.length > 0 ? (
          <CardDeck>
            {allFutureFamilyEvents
              .sort((a, b) => {
                // Convert the timestamps dates from strings to date objects.
                let da = new Date(a.node.body[0].primary.date_time),
                  db = new Date(b.node.body[0].primary.date_time)
                return da - db
              })
              .map((event, i) => {
                return <EventOverviewCard event={event} key={i} />
              })}
          </CardDeck>
        ) : null}
      </>
    </Layout>
  )
}

export default KinderFamilienProgramm

export const query = graphql`
  {
    prismic {
      allPages(
        where: { page_type: "Event", event_type: "Kinder & Familien Programm" }
        lang: "de-ch"
      ) {
        totalCount
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                  image
                  event_teaser
                }
              }
            }
          }
        }
      }
      allFamilienprogramms(lang: "de-ch") {
        edges {
          node {
            page_title
            text
          }
        }
      }
    }
  }
`
